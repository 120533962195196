<template>
  <div
    :class="[
      'setup-step',
      currentSetupStep >= 3 ? 'step-completed' : '',
      activeStep == 3 ? 'step-active' : '',
    ]"
  >
    <div class="step-container">
      <div class="steps-container">
        <div class="steps-sub-label">Seed List Setup</div>
      </div>

      <div class="step-contents">
        <div class="step-contents-body">
          <a-config-provider :theme="{ token: { controlHeight: 40 } }">
            <div class="form-group">
              <a-checkbox
                :checked="usingWeightedList == false"
                @change="handleChangeUsingWeightedList(false)"
              >
                Default Seed List
              </a-checkbox>
              <a-checkbox
                :checked="usingWeightedList == true"
                @change="handleChangeUsingWeightedList(true)"
              >
                Weighted Seed List
              </a-checkbox>
            </div>
          </a-config-provider>

          <a-alert v-if="usingWeightedList == true">
            <template #description>
              <h2 class="sub-heading1">
                <img src="@/assets/img/icons/remark.svg" />File Format
              </h2>
              <p class="remark-description">
                Please upload a csv/txt file with a single column containing
                email addresses
              </p>
              <h2 class="sub-heading1">
                <img src="@/assets/img/icons/remark.svg" />About Weighting
              </h2>
              <p class="remark-description">
                A Weighted Seed List works best if your address list contains a
                mixed number of emails from the primary email providers (Gmail,
                Yahoo, Hotmail, AOL) and if your list contains a large number of
                emails from those primary providers.
              </p>
            </template>
          </a-alert>
          <a-config-provider :theme="getSpecialTheme()">
            <template v-if="usingWeightedList == true">
              <input
                id="seeds-file"
                type="file"
                @change="isUploadedFile = true"
              />
              <a-button
                type="primary"
                class="btn-continue"
                :disabled="!isUploadedFile"
                @click="handleUploadAddressList"
              >
                Upload your address list
              </a-button>
              <p class="instructions mt-10">
                Text file should only contain email address in a .txt or .csv
                format
              </p>
              <p class="instructions">
                <strong>Note:</strong>
                Your data is only used to create a personalized seed list. Mail
                Monitor does not use or store your address list in our system.
              </p>

              <template v-if="scanningProgress >= 0">
                <p class="scanning-message">
                  {{
                    scanningProgress == 100
                      ? 'Scanning completed'
                      : 'Scanning your contacts list'
                  }}
                </p>
                <a-progress
                  :percent="scanningProgress"
                  stroke-color="#F9A334"
                />
              </template>
            </template>
          </a-config-provider>
        </div>
      </div>
    </div>
    <div class="step-footer">
      <a-button
        v-if="usingWeightedList == true"
        type="primary"
        :disabled="!isFinishedUploadSeeds"
        @click="handleContinue"
      >
        Continue
      </a-button>
      <a-button v-else type="primary" @click="handleContinue">
        Continue
      </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';

export default defineComponent({
  name: 'Step-3',
  props: [
    'activeStep',
    'serviceType',
    'currentSetupStep',
    'initialUsingWeightedList',
  ],
  setup(props, { emit }) {
    const store = useStore();
    const usingWeightedList = ref(false);
    const isUploadedFile = ref(false);
    const isFinishedUploadSeeds = ref(false);
    const scanningProgress = ref(-1);
    const domainCount = ref({});

    onMounted(() => {
      usingWeightedList.value = props.initialUsingWeightedList;
    });

    const handleChangeStep = () => {
      emit('updateActiveStep', 3);
    };

    const handleChangeUsingWeightedList = (value) => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.SetupApi.updateListSetup(value === true ? 1 : 0)
        .then(() => {
          usingWeightedList.value = value;
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleUploadAddressList = () => {
      domainCount.value = {};
      const files = document.getElementById('seeds-file').files;
      if (files.length !== 1) return;

      scanningProgress.value = 0;
      const navigator = new window.LineNavigator(files[0]);

      const indexToStartWidth = 0;
      const numberOfLines = 10;
      navigator.readLines(
        indexToStartWidth,
        numberOfLines,
        function linesReadHandler(err, index, lines, isEof, progress) {
          scanningProgress.value = progress;
          if (progress === 100) {
            isFinishedUploadSeeds.value = true;
          }

          if (err) throw err;

          for (var i = 0; i < lines.length; i++) {
            var line = lines[i];
            line = line.split(',')[0];
            var parts = line.split('@');

            if (!domainCount.value[parts[1]]) {
              domainCount.value[parts[1]] = 1;
            } else {
              domainCount.value[parts[1]] += 1;
            }
          }

          if (isEof) {
            var filteredObject = {};
            Object.keys(domainCount.value).forEach((key) => {
              if (!key || key === 'undefined') return;
              filteredObject[key] = domainCount.value[key];
            });

            domainCount.value = filteredObject;
            return;
          }

          navigator.readLines(
            index + numberOfLines,
            numberOfLines,
            linesReadHandler
          );
        }
      );
    };

    const handleContinue = () => {
      if (usingWeightedList.value === true) {
        store.dispatch(STORE_EVENT.START_LOADING_PAGE);

        api.SetupApi.saveWeightedSeedListProportions(domainCount.value)
          .then(() => {
            emit('continue');
          })
          .catch(() => {
            store.dispatch(STORE_EVENT.START_LOADING_PAGE);
          });
      } else {
        emit('continue');
      }
    };

    return {
      usingWeightedList,
      isUploadedFile,
      isFinishedUploadSeeds,
      scanningProgress,
      handleChangeStep,
      handleChangeUsingWeightedList,
      handleUploadAddressList,
      handleContinue,
    };
  },
});
</script>

<style lang="less" scoped>
@import './Step3.less';
</style>
