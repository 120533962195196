<template>
  <a-layout-header style="background-color: #fff; padding: 0">
    <div class="header-title">{{ pageName }}</div>

    <div class="profile-section">
      <header-page-modal
        :showReputationSetup="showReputationSetup"
        :showSetupAll="showSetupAll"
        @closeReputationSetup="showReputationSetup = false"
        @closeSetupAll="showSetupAll = false"
      />

      <a-dropdown :trigger="['click']">
        <a class="profile">
          Hello, {{ user.customer_name }}&nbsp;
          <down-outlined />
        </a>
        <template #overlay>
          <a-menu>
            <div class="menu-item">
              <a-menu-item @click="handleAccount">Account</a-menu-item>
            </div>
            <div class="menu-item">
              <a-sub-menu
                v-if="parentUser && parentUser.service_type == 104"
                title="Switch Account"
              >
                <div class="menu-item">
                  <a-menu-item
                    @click="
                      handleSignInSubAccount(parentUser.primary_contact_email)
                    "
                  >
                    {{ parentUser.customer_name }}
                  </a-menu-item>
                </div>
                <div
                  v-for="subAccount in subAccounts"
                  :key="subAccount.customer_id"
                  class="menu-item"
                >
                  <a-menu-item
                    @click="
                      handleSignInSubAccount(subAccount.primary_contact_email)
                    "
                  >
                    {{ subAccount.company_name }}
                  </a-menu-item>
                </div>
              </a-sub-menu>
            </div>
            <div class="menu-item">
              <a-menu-item
                v-if="user.service_type && user.service_type == 104"
                @click="handleSubAccounts"
              >
                Sub-Accounts
              </a-menu-item>
            </div>
            <div class="menu-item">
              <a-menu-item
                v-if="
                  user.service_type &&
                  (user.service_type == 103 ||
                    user.service_type == 104 ||
                    customerPreference.enable_extra_login_flag == 1)
                "
                @click="handleManageUserLogins"
              >
                Manage User Logins
              </a-menu-item>
            </div>
            <template v-if="user.service_type && user.service_type != 100">
              <div class="menu-item">
                <a-sub-menu title="Setup">
                  <div class="menu-item">
                    <a-menu-item @click="handleSetup('all')">
                      Seed Setup
                    </a-menu-item>
                  </div>
                  <div class="menu-item">
                    <a-menu-item @click="handleSetup('reputation')">
                      Reputation Setup
                    </a-menu-item>
                  </div>
                  <div class="menu-item">
                    <a-menu-item @click="handleChangeRoute('/alerts/setup')">
                      Alert Setup
                    </a-menu-item>
                  </div>
                </a-sub-menu>
              </div>
            </template>
            <div class="menu-item">
              <a-menu-item @click="handleLogout">Logout</a-menu-item>
            </div>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>

<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';
import { convertDateToString, getInitialSearchDateRange } from '@/utils/util';

import HeaderPageModal from './components/HeaderPageModal/HeaderPageModal.vue';

export default defineComponent({
  name: 'Header-Component',
  components: {
    DownOutlined,
    HeaderPageModal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const subAccounts = ref([]);
    const user = ref({});
    const customerPreference = ref({});
    const parentUser = ref(null);
    const searchParams = ref({
      dateRange: getInitialSearchDateRange(store),
    });
    const showReputationSetup = ref(false);
    const showSetupAll = ref(false);

    // const handleCollapsed = () => {
    //   emit('handleCollapsed');
    // };

    onMounted(() => {
      subAccounts.value = JSON.parse(localStorage.getItem('subaccounts'));
      user.value = JSON.parse(localStorage.getItem('user'));
      customerPreference.value = JSON.parse(
        localStorage.getItem('customer_preference')
      );
      parentUser.value = JSON.parse(localStorage.getItem('parent_user'));

      getUserTimezone();

      displaySetup();
    });

    const handleSubAccounts = () => {
      router.push('/account/sub-account');
    };

    const getUserTimezone = () => {
      const {
        dateRange: { startDate, endDate },
      } = searchParams.value;

      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.UserApi.me(
        `${convertDateToString(startDate)} 00:00:00`,
        `${convertDateToString(endDate)} 23:59:59`
      )
        .then((res) => {
          localStorage.setItem(
            'time_zone_pdt_offset',
            res.time_zone_pdt_offset
          );
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const displaySetup = () => {
      const currentSetupStep = localStorage.getItem('current_setup_step');
      if (currentSetupStep === 'null' || currentSetupStep <= 4) {
        showSetupAll.value = true;
      }
    };

    const handleManageUserLogins = () => {
      router.push('/account/extra-login');
    };

    const handleLogout = () => {
      localStorage.clear();
      window.location.replace('/');
    };

    const handleSignInSubAccount = (email) => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.UserApi.signinSubaccount(email)
        .then((data) => {
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem(
            'customer_preference',
            JSON.stringify(data.customer_preference)
          );
          localStorage.setItem('current_setup_step', data.current_setup_step);
          localStorage.setItem('parent_user', JSON.stringify(data.parent_user));
          localStorage.setItem(
            'subaccounts',
            JSON.stringify(data.sub_accounts)
          );
          window.location.replace('/delivery/report');
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleAccount = () => {
      router.push('/account/update');
    };

    const handleChangeRoute = (path) => {
      router.push(path);
    };

    const handleSetup = (path) => {
      if (path == 'reputation') {
        showReputationSetup.value = true;
      } else if (path == 'all') {
        showSetupAll.value = true;
      }
    };

    return {
      pageName: computed(() => store.state.pageName),
      subAccounts,
      user,
      customerPreference,
      parentUser,
      showReputationSetup,
      showSetupAll,
      handleSubAccounts,
      // handleCollapsed,
      handleLogout,
      handleSignInSubAccount,
      handleManageUserLogins,
      handleAccount,
      handleChangeRoute,
      handleSetup,
    };
  },
});
</script>

<style lang="less">
@import './Header.less';
</style>
