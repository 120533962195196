<template>
  <a-modal
    width="80%"
    :open="open"
    centered
    :closable="false"
    :footer="null"
    @cancel="handleClose"
    wrap-class-name="header-modal reputation-modal"
  >
    <a-spin :spinning="loading">
      <a-layout>
        <a-layout-header>
          <div class="title">SNDS Reputation Setup</div>
          <div class="close-button">
            <a-config-provider :theme="getSpecialTheme()">
              <a-button @click="handleClose" type="text">
                <i class="fa-solid fa-xmark" />
              </a-button>
            </a-config-provider>
          </div>
        </a-layout-header>
        <a-layout-content class="main-content">
          <p>Add your SNDS token below to get started.</p>

          <a-alert show-icon>
            <template #icon>
              <img src="@/assets/img/bulb-icon.png" />
            </template>
            <template #description>
              <p>To obtain the URL:</p>

              <ul>
                <li>
                  Sign up or log into SNDS:
                  <a href="https://postmaster.live.com/snds/" target="_blank">
                    https://postmaster.live.com/snds/
                  </a>
                </li>
                <li>Follow instructions to verify IP ownership</li>
                <li>
                  Copy the data URL (appears first) from the following
                  automation page:
                  <span style="color: #2f858d">
                    https://postmaster.live.com/snds/auto.aspx
                  </span>
                </li>
              </ul>

              <p>
                The SNDS URL will be formatted as follows:
                <span style="color: #2f858d">
                  https://postmaster.live.com/snds/data.aspx?key=XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
                </span>
              </p>
              <p>
                Please copy only the key portion of the url. This is your token.
              </p>
            </template>
          </a-alert>

          <br />

          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <div class="form-group">
                <p class="form-label">Add SNDS Token</p>
                <a-input class="w-full" v-model:value="newToken" />
              </div>
            </a-col>
            <a-col :span="6">
              <a-config-provider :theme="getSpecialTheme()">
                <div style="margin-top: 25px">
                  <a-button type="primary" @click="handleInsertSndsUrl">
                    Add
                  </a-button>
                </div>
              </a-config-provider>
            </a-col>
          </a-row>

          <a-config-provider :theme="getSpecialTheme()">
            <a-table
              :columns="columns"
              :dataSource="dataSource"
              :pagination="false"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.name == 'action'">
                  <a-button
                    type="link"
                    @click="handleDeleteSndsUrl(record.snds_token_id)"
                  >
                    <img src="@/assets/img/delete.svg" />
                  </a-button>
                </template>
              </template>
            </a-table>
            <template #renderEmpty>
              <div class="render-empty items-center flex flex-col">
                <img src="@/assets/img/poker_chip.svg" />
                <span>
                  <b>No tokens added</b>
                  <br />
                  There's nothing to show at the moment.
                  <br />
                </span>
              </div>
            </template>
          </a-config-provider>
        </a-layout-content>
        <a-layout-footer>
          <a-config-provider :theme="getSpecialTheme()">
            <a-button type="primary" @click="handleClose">Finish</a-button>
          </a-config-provider>
        </a-layout-footer>
      </a-layout>
    </a-spin>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { defineComponent, ref, watch } from 'vue';

import api from '@/api';

const columns = [
  {
    title: 'SNDS TOKEN',
    dataIndex: 'token',
  },
  {
    title: '',
    name: 'action',
    align: 'right',
  },
];

export default defineComponent({
  name: 'ReputationAdministrationModal',
  props: ['open'],
  emits: ['close'],
  setup(props, { emit }) {
    const loading = ref(false);
    const dataSource = ref([]);
    const newToken = ref('');

    watch(
      () => props.open,
      (open) => {
        if (open) {
          newToken.value = '';
          getSndsUrl();
        }
      }
    );

    const getSndsUrl = () => {
      loading.value = true;

      api.SndsApi.getSndsUrl()
        .then((res) => {
          dataSource.value = res.data;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleInsertSndsUrl = () => {
      const regex =
        /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
      if (!regex.test(newToken.value)) {
        message.error(
          'Warning, the token format is invalid. It should be the key portion only of the URL'
        );
      } else {
        loading.value = true;

        api.SndsApi.insertSndsUrl(newToken.value)
          .then(() => {
            message.success('The token added successfully!');
            newToken.value = '';
            getSndsUrl();
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              message.error(err.response.data.detail);
            }
            loading.value = false;
          });
      }
    };

    const handleDeleteSndsUrl = (sndsTokenId) => {
      Modal.confirm({
        title: 'Remove SNDS Url Confirmation',
        content: 'Are you sure want to remove this url?',
        onOk() {
          loading.value = true;

          api.SndsApi.deleteSndsUrl(sndsTokenId)
            .then(() => {
              getSndsUrl();
            })
            .catch(() => {
              loading.value = false;
            });
        },
      });
    };

    const handleClose = () => {
      if (loading.value) return;
      emit('close');
    };

    return {
      columns,
      loading,
      dataSource,
      newToken,
      handleInsertSndsUrl,
      handleDeleteSndsUrl,
      handleClose,
    };
  },
});
</script>

<style lang="less" scoped>
@import './ReputationAdministrationModal.less';
</style>

<style lang="less">
.reputation-modal {
  .main-content {
    padding: 10px 50px 10px 50px;
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .ant-layout {
    height: calc(90vh);
  }

  .ant-table-wrapper {
    overflow-y: auto;
  }

  .ant-layout-header {
    border-bottom: none;
  }
}
</style>
