<template>
  <div
    :class="[
      'setup-step',
      currentSetupStep >= 2 ? 'step-completed' : '',
      activeStep == 2 ? 'step-active' : '',
    ]"
  >
    <div class="step-container">
      <div class="steps-container">
        <div class="steps-sub-label">
          Choose Validation Method. Add these Email Seeds to your campaigns
        </div>

        <div class="step-action step-action-lg">
          <div class="form-group step-checkbox">
            <a-config-provider :theme="{ token: { controlHeight: 40 } }">
              <a-checkbox
                :checked="emailValidation.corporate_account_flag == 0"
                @change="handleChangeValidationMethod(0)"
              >
                From Address
              </a-checkbox>
            </a-config-provider>
            <p class="checkbox-description">
              This option is preferable to use if you test a single campaign
              once only within 24h. Maintain a list of email addresses used in
              the "From" headers of your campaigns. This will allow us to track
              your test campaign performance.
            </p>
          </div>

          <div class="form-group step-checkbox">
            <a-config-provider :theme="{ token: { controlHeight: 40 } }">
              <a-checkbox
                :checked="emailValidation.corporate_account_flag == 1"
                @change="handleChangeValidationMethod(1)"
              >
                X-Header
              </a-checkbox>
            </a-config-provider>
            <p class="checkbox-description">
              Use this option if you are going to send multiple subject lines of
              the same campaign to test variations, within 24h. Remember to
              include X-Header with a unique code for each campaign.
            </p>
          </div>

          <div class="form-group step-checkbox">
            <a-config-provider :theme="{ token: { controlHeight: 40 } }">
              <a-checkbox
                :checked="emailValidation.corporate_account_flag == 2"
                @change="handleChangeValidationMethod(2)"
              >
                HTML Code
              </a-checkbox>
            </a-config-provider>
            <p class="checkbox-description">
              Include an HTML comment with a unique code for each campaign. This
              method can be used to override X-Headers inserted by Email Service
              Providers.
            </p>
          </div>
        </div>

        <span
          class="step-label"
          v-if="emailValidation.corporate_account_flag == 0"
          >Email address</span
        >
        <div
          class="step-action step-action-small"
          v-if="emailValidation.corporate_account_flag == 0"
        >
          <a-input v-model:value="inputFromEmail" />
          <a-button
            type="primary"
            @click="handleAddFromEmail"
            :disabled="addressLimitExceeded"
          >
            Add
          </a-button>
        </div>
      </div>

      <div class="step-contents">
        <div class="step-contents-body">
          <a-table
            v-if="emailValidation.corporate_account_flag == 0"
            size="small"
            :columns="columns"
            :dataSource="emails"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.name == 'delete'">
                <a-button
                  type="link"
                  danger
                  @click="handleDeleteFromEmail(record)"
                >
                  <template #icon>
                    <i class="fa fa-trash-o" />
                  </template>
                </a-button>
              </template>
            </template>
          </a-table>

          <a-alert v-else-if="emailValidation.corporate_account_flag == 1">
            <template #description>
              <h2 class="sub-heading1">
                <img src="@/assets/img/icons/remark.svg" />To use X-Headers with
                Campaign IDs, ensure the followings:
              </h2>
              <p class="remark-description">
                1. Use the following structure for creating your X Campaign ID:
                <span class="span-bold">X-CampaignID: esp-20191211230400</span>
              </p>
              <p class="remark-description">
                Anything can be written in place of the x's as an identifier for
                your test campaign.
              </p>
              <p class="remark-description">
                2. Each campaign must have a unique X Campaign ID.
              </p>
            </template>
          </a-alert>

          <a-alert v-else-if="emailValidation.corporate_account_flag == 2">
            <template #description>
              <h2 class="sub-heading1">
                <img src="@/assets/img/icons/remark.svg" />Include Campaign IDs
                in the Email Body
              </h2>
              <p class="remark-description">
                Add an HTML comment with a unique campaign ID to the email body
              </p>
              <p class="remark-description">Example:</p>
              <p class="remark-description">
                <span class="span-bold">{{ htmlTracking }}</span>
              </p>
              <p class="remark-description">
                Note: this method can be used to override X-Headers inserted by
                Email Service Providers.
              </p>
            </template>
          </a-alert>
        </div>
      </div>
    </div>
    <div class="step-footer">
      <a-button type="primary" @click="handleContinue"> Continue </a-button>
    </div>
  </div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';

const columns = [
  { title: 'Email', dataIndex: 'customer_from_email' },
  { title: '', name: 'delete', width: 50 },
];

export default defineComponent({
  name: 'Step-2',
  props: [
    'activeStep',
    'initialEmailValidation',
    'htmlTracking',
    'initialEmails',
    'currentSetupStep',
  ],
  setup(props, { emit }) {
    const store = useStore();
    const emailValidation = ref({});
    const emails = ref([]);
    const inputFromEmail = ref('');
    const addressLimitExceeded = ref(false);

    onMounted(() => {
      emailValidation.value = props.initialEmailValidation;
      emails.value = props.initialEmails;
      addressLimitExceeded.value = props.addressLimitExceeded;
    });

    const handleChangeValidationMethod = (value) => {
      if (emailValidation.value.corporate_account_flag === value) return;

      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.SetupApi.updateValidationMethod(value)
        .then(() => {
          emailValidation.value.corporate_account_flag = value;
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleChangeStep = () => {
      emit('updateActiveStep', 2);
    };

    const getFromEmail = (messageStr) => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.SetupApi.getFromEmail()
        .then((data) => {
          emails.value = data;

          api.UserApi.addressLimitExceeded()
            .then((result) => {
              addressLimitExceeded.value = result;

              if (messageStr) {
                inputFromEmail.value = '';
                message.success(messageStr);
              }
            })
            .catch((error) => {
              console.error('Error fetching addressLimitExceeded:', error);
            });
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleAddFromEmail = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.SetupApi.addNewFromEmail(inputFromEmail.value)
        .then(() => {
          getFromEmail('New From Email Added');
        })
        .catch(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleDeleteFromEmail = (record) => {
      Modal.confirm({
        title: 'Delete Confirmation',
        content: 'Are you sure want to remove this email?',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          return api.SetupApi.deleteFromEmail(
            record.customer_from_email_id
          ).then(() => {
            getFromEmail('From Email Deleted Successfully');
          });
        },
      });
    };

    const handleContinue = () => {
      emit('continue');
    };

    return {
      emails,
      emailValidation,
      columns,
      inputFromEmail,
      addressLimitExceeded,
      handleChangeValidationMethod,
      handleChangeStep,
      handleAddFromEmail,
      handleDeleteFromEmail,
      handleContinue,
    };
  },
});
</script>

<style lang="less" scoped>
@import './Step2.less';
</style>
