<template>
  <template v-if="showSubAccountAddButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openNewSubAccountModal = true"
      >
        <template #icon>
          <i class="fa fa-cog" />
        </template>
        Add Account
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showExtraLoginAddButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openNewExtraLoginModal = true"
      >
        <template #icon>
          <i class="fa-solid fa-plus" />
        </template>
        Add User Login
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showRecordGeneratorButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openRecordGeneratorModal = true"
      >
        Generate Record
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showDmarcSetupButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openDmarcSetupModal = true"
      >
        <template #icon>
          <i class="fa fa-cog" />
        </template>
        Setup
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showRdnsDomainSetupButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openRdnsDomainSetupModal = true"
      >
        <template #icon>
          <i class="fa fa-cog" />
        </template>
        Domain Setup
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showPostmasterSetupButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openPostmasterSetupModal = true"
      >
        <template #icon>
          <i class="fa fa-cog" />
        </template>
        Setup
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showReputationAdministrationButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openReputationAdministrationModal = true"
      >
        <template #icon>
          <i class="fa fa-cog" />
        </template>
        Setup
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showReputationSetupButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openReputationSetupModal = true"
      >
        <template #icon>
          <i class="fa fa-cog" />
        </template>
        Setup
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showAlertSetupButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button type="primary" class="btn-setup" @click="handleOpenAlertSetup">
        <template #icon>
          <i class="fa fa-cog" />
        </template>
        Setup
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showAlertDetailButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="handleOpenAlertDetailsModal"
      >
        <template #icon>
          <i class="fa-solid fa-plus" />
        </template>
        New alert
      </a-button>
    </a-config-provider>
  </template>
  <template v-else-if="showSetupAllButton">
    <a-config-provider :theme="getSpecialTheme()">
      <a-button
        type="primary"
        class="btn-setup"
        @click="openSetupAllModal = true"
      >
        <template #icon>
          <i class="fa fa-cog" />
        </template>
        Setup
      </a-button>
    </a-config-provider>
  </template>

  <record-generator
    :open="openRecordGeneratorModal"
    @close="openRecordGeneratorModal = false"
  />

  <dmarc-setup-modal
    :open="openDmarcSetupModal"
    @close="openDmarcSetupModal = false"
  />

  <postmaster-setup
    :open="openPostmasterSetupModal"
    @close="openPostmasterSetupModal = false"
  />

  <rdns-domain-setup-modal
    :open="openRdnsDomainSetupModal"
    @close="openRdnsDomainSetupModal = false"
  />

  <reputation-administration-modal
    :open="openReputationAdministrationModal"
    @close="openReputationAdministrationModal = false"
  />

  <reputation-setup-modal
    :open="isReputationSetupModalOpen"
    @close="closeReputationSetupModal"
  />

  <alert-details-modal
    :openDetails="openAlertDetailsModal"
    :all-alert-types="allAlertTypes"
    :all-event-types="allEventTypes"
    :isps="isps"
    @close="openAlertDetailsModal.open = false"
  />

  <new-extra-login-modal
    :open="openNewExtraLoginModal"
    @close="openNewExtraLoginModal = false"
  />

  <new-sub-account-modal
    :open="openNewSubAccountModal"
    @close="openNewSubAccountModal = false"
  />

  <setup-all-modal :open="isSetupAllModalOpen" @close="closeSetupAllModal" />
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';
import { DETAILS_TYPE } from '@/utils/util';
import NewExtraLoginModal from '@/views/Account/ExtraLogin/components/NewExtraLoginModal/NewExtraLoginModal.vue';
import NewSubAccountModal from '@/views/Account/SubAccount/components/NewSubAccountModal/NewSubAccountModal.vue';
import AlertDetailsModal from '@/views/Alerts/AlertSetup/components/AlertDetailsModal/AlertDetailsModal.vue';
import RecordGenerator from '@/views/DMARC/RecordGenerator/RecordGenerator.vue';
import DmarcSetupModal from '@/views/DMARC/Summary/components/DmarcSetupModal/DmarcSetupModal.vue';
import ReputationAdministrationModal from '@/views/ReputationMonitors/MicrosoftSNDS/components/ReputationAdministrationModal/ReputationAdministrationModal.vue';
import PostmasterSetup from '@/views/ReputationMonitors/PostmasterSetup/PostmasterSetup.vue';
import RdnsDomainSetupModal from '@/views/ReputationMonitors/RDNS/components/RdnsDomainSetupModal/RdnsDomainSetupModal.vue';
import ReputationSetupModal from '@/views/ReputationMonitors/ReputationSetup/components/ReputationSetupModal/ReputationSetupModal.vue';
import SetupAllModal from '@/views/Setup/All/components/SetupAllModal/SetupAllModal.vue';

export default defineComponent({
  name: 'Header-Page-Modal',
  props: ['showReputationSetup', 'showSetupAll'],
  emits: ['closeReputationSetup', 'closeSetupAll'],
  components: {
    AlertDetailsModal,
    RecordGenerator,
    DmarcSetupModal,
    RdnsDomainSetupModal,
    ReputationAdministrationModal,
    ReputationSetupModal,
    PostmasterSetup,
    NewExtraLoginModal,
    NewSubAccountModal,
    SetupAllModal,
  },
  computed: {
    showSubAccountAddButton() {
      return this.$route.meta.showSubAccountAddButton;
    },
    showExtraLoginAddButton() {
      return this.$route.meta.showExtraLoginAddButton;
    },
    showRecordGeneratorButton() {
      return this.$route.meta.showRecordGeneratorButton;
    },
    showDmarcSetupButton() {
      return this.$route.meta.showDmarcSetupButton;
    },
    showPostmasterSetupButton() {
      return this.$route.meta.showPostmasterSetupButton;
    },
    showRdnsDomainSetupButton() {
      return this.$route.meta.showRdnsDomainSetupButton;
    },
    showReputationAdministrationButton() {
      return this.$route.meta.showReputationAdministrationButton;
    },
    showReputationSetupButton() {
      return this.$route.meta.showReputationSetupButton;
    },
    showAlertSetupButton() {
      return this.$route.meta.showAlertSetupButton;
    },
    showAlertDetailButton() {
      return this.$route.meta.showAlertDetailButton;
    },
    showSetupAllButton() {
      return this.$route.meta.showSetupAllButton;
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    // Modal information
    const openRecordGeneratorModal = ref(false);
    const openDmarcSetupModal = ref(false);
    const openPostmasterSetupModal = ref(false);
    const openReputationAdministrationModal = ref(false);
    const openReputationSetupModal = ref(false);
    const openNewSubAccountModal = ref(false);
    const openNewExtraLoginModal = ref(false);
    const openSetupAllModal = ref(false);
    const openRdnsDomainSetupModal = ref(false);

    // Alert detail modal info
    const openAlertDetailsModal = ref({
      open: false,
      type: DETAILS_TYPE.CREATE,
      data: null,
    });
    const allAlertTypes = ref([]);
    const allEventTypes = ref([]);
    const isps = ref([]);
    const ready = ref(false);

    const handleOpenAlertDetailsModal = () => {
      if (ready.value === true) {
        openAlertDetailsModal.value = {
          open: true,
          type: DETAILS_TYPE.CREATE,
          data: null,
        };

        return;
      }

      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      Promise.all([
        api.AlertApi.getAllEventTypes(),
        api.AlertApi.getAllAlertTypes(),
        api.DeliveryApi.getIsps(),
      ])
        .then((res) => {
          allEventTypes.value = res[0];
          allAlertTypes.value = res[1];
          isps.value = res[2].data;
          isps.value.sort((a, b) => {
            return a.isp_name.localeCompare(b.isp_name);
          });
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
          ready.value = true;

          openAlertDetailsModal.value = {
            open: true,
            type: DETAILS_TYPE.CREATE,
            data: null,
          };
        });
    };

    const handleOpenAlertSetup = () => {
      router.push('/alerts/setup');
    };

    const isReputationSetupModalOpen = computed(() => {
      return openReputationSetupModal.value || props.showReputationSetup;
    });

    const closeReputationSetupModal = () => {
      openReputationSetupModal.value = false;
      emit('closeReputationSetup');
    };

    const isSetupAllModalOpen = computed(() => {
      return openSetupAllModal.value || props.showSetupAll;
    });

    const closeSetupAllModal = () => {
      openSetupAllModal.value = false;
      emit('closeSetupAll');
    };

    return {
      openRecordGeneratorModal,
      openDmarcSetupModal,
      openPostmasterSetupModal,
      openRdnsDomainSetupModal,
      openReputationAdministrationModal,
      openReputationSetupModal,
      openNewSubAccountModal,
      openNewExtraLoginModal,
      openAlertDetailsModal,
      openSetupAllModal,
      allAlertTypes,
      allEventTypes,
      isps,
      handleOpenAlertDetailsModal,
      handleOpenAlertSetup,
      isReputationSetupModalOpen,
      closeReputationSetupModal,
      isSetupAllModalOpen,
      closeSetupAllModal,
    };
  },
});
</script>

<style lang="less">
@import './HeaderPageModal.less';
</style>
