<template>
  <div
    :class="[
      'setup-step',
      currentSetupStep >= 1 ? 'step-completed' : '',
      activeStep == 1 ? 'step-active' : '',
    ]"
  >
    <div class="step-container">
      <div class="steps-container">
        <div class="steps-sub-label">
          Tell us what domain or IP address you would like us to monitor the
          sender reputation for. Enter the domain or IP address and add an
          identification label, then click "ADD". To remove a domain or IP
          address from your monitoring list, click the trashcan icon.
        </div>
        <span class="step-label">Add custom domain</span>
        <div class="step-action">
          <a-input
            v-model:value="inputDomainOrIp"
            placeholder="Add Domain/Ip"
          />
          <a-input v-model:value="inputLabel" placeholder="Add Label" />
          <a-button type="primary" class="w-full" @click="handleAddDomainOrIp">
            <template #icon>
              <i class="fa fa-plus mr-5" />
            </template>
            Add
          </a-button>
        </div>
      </div>

      <div class="step-contents">
        <div class="step-contents-body">
          <a-table size="small" :dataSource="domains" :columns="columns">
            <template #bodyCell="{ column, record }">
              <template v-if="column.name == 'delete'">
                <a-button
                  type="link"
                  danger
                  @click="handleDeleteDomainOrIp(record)"
                >
                  <template #icon>
                    <i class="fa fa-trash-o" />
                  </template>
                </a-button>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div class="step-footer">
      <a-button type="primary" @click="handleContinue"> Continue </a-button>
    </div>
  </div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';
import { checkIPDomain } from '@/utils/util';

const columns = [
  {
    title: 'Domain / IP',
    dataIndex: 'sendingServerIp',
  },
  {
    title: 'Label',
    dataIndex: 'label',
  },
  {
    title: '',
    name: 'delete',
    align: 'center',
    width: 100,
  },
];

export default defineComponent({
  name: 'Step-1',
  props: ['initialDomains', 'activeStep', 'currentSetupStep'],
  setup(props, { emit }) {
    const store = useStore();
    const domains = ref([]);
    const inputDomainOrIp = ref('');
    const inputLabel = ref('');

    onMounted(() => {
      domains.value = props.initialDomains;
    });

    const handleDeleteDomainOrIp = (record) => {
      Modal.confirm({
        title: 'Delete Confirmation',
        content: 'Are you sure want to remove domain or ip address?',
        okText: 'Yes',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        cancelText: 'No',
        onOk() {
          return api.ReputationApi.deleteDomainOrIp(
            record.sendingServerId,
            record.sendingServerIp
          ).then(() => {
            message.success('Domain or Ip deleted successfully.');
            getAllDomainOrIp();
          });
        },
      });
    };

    const handleAddDomainOrIp = () => {
      if (!checkIPDomain(inputDomainOrIp.value)) {
        message.error('Invalid Domain or IP');
      } else {
        store.dispatch(STORE_EVENT.START_LOADING_PAGE);

        api.ReputationApi.addDomainOrIp(inputDomainOrIp.value, inputLabel.value)
          .then(() => {
            inputDomainOrIp.value = '';
            inputLabel.value = '';
            message.success('Domain or Ip added successfully.');
            getAllDomainOrIp();
          })
          .catch(() => {
            store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
          });
      }
    };

    const getAllDomainOrIp = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.ReputationApi.getAllDomainOrIp()
        .then((res) => {
          domains.value = res.data;
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleChangeStep = () => {
      emit('updateActiveStep', 1);
    };

    const handleContinue = () => {
      emit('continue');
    };

    return {
      columns,
      domains,
      inputDomainOrIp,
      inputLabel,
      handleDeleteDomainOrIp,
      handleAddDomainOrIp,
      handleChangeStep,
      handleContinue,
    };
  },
});
</script>
