<template>
  <div
    :class="[
      'setup-step',
      currentSetupStep >= stepNum ? 'step-completed' : '',
      activeStep == stepNum ? 'step-active' : '',
    ]"
  >
    <div class="step-container">
      <div class="steps-container">
        <div class="steps-sub-label">
          Copy Email Seeds Add these Email Seeds to your campaigns
        </div>
      </div>

      <div class="step-contents">
        <div class="step-contents-body">
          <a-alert>
            <template #description>
              <h2 class="sub-heading1">
                <img src="@/assets/img/icons/remark.svg" />Your Key Seeds are:
              </h2>
              <p
                class="remark-description"
                v-for="keyEmailSeed in keyEmailSeedsOnly"
                :key="keyEmailSeed"
              >
                <span class="span-bold">{{ keyEmailSeed }}</span>
              </p>
              <p class="remark-description">
                Ensure this seed address is included when you copy the list
                below and upload it into your sending solution. If this seed
                address is not included, results will not populate. We have
                included it at the top of the list below for connivence.
              </p>
            </template>
          </a-alert>

          <p class="step-contents-instruction">
            Send your test emails to the list below and we will return
            deliverability results under the Deliverability Monitors tab.
          </p>

          <div class="custom-card-container">
            <div class="custom-card-header">
              <div class="custom-card-header-title">
                <span>Seed List</span>
              </div>
              <div class="custom-card-header-content-right">
                <a-button type="primary" @click="handleDownload">
                  <template #icon>
                    <i class="fa fa-download" />
                  </template>
                </a-button>
                <a-button type="primary" @click="handleCopy">
                  <template #icon>
                    <i class="fa fa-copy" />
                  </template>
                </a-button>
              </div>
            </div>
            <div class="custom-card-content">
              <a-textarea
                id="email-seeds"
                :auto-size="{ minRows: 10, maxRows: 10 }"
                :value="keyEmailSeedsStr"
                :bordered="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-footer">
      <a-button type="primary" @click="handleContinue">
        Yes, I sent my campaign to the email seeds
      </a-button>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import $ from 'jquery';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Step-5',
  props: [
    'activeStep',
    'keyEmailSeeds',
    'keyEmailSeedsOnly',
    'stepNum',
    'currentSetupStep',
  ],
  setup(props, { emit }) {
    const keyEmailSeedsStr = computed(
      () => `email_address\n${(props.keyEmailSeeds || []).join('\n')}`
    );

    const handleChangeStep = () => {
      emit('updateActiveStep', props.stepNum);
    };

    const handleCopy = () => {
      $('#email-seeds').select();
      document.execCommand('copy');
      message.success('Copied');
    };

    const handleDownload = () => {
      var element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' +
          encodeURIComponent(keyEmailSeedsStr.value)
      );
      element.setAttribute('download', 'MailMonitorSeedList');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    };

    const handleContinue = () => {
      emit('continue');
      localStorage.setItem('current_setup_step', props.activeStep);
    };

    return {
      keyEmailSeedsStr,
      handleChangeStep,
      handleCopy,
      handleDownload,
      handleContinue,
    };
  },
});
</script>

<style lang="less" scoped>
@import './Step5.less';
</style>
