<template>
  <div
    :class="[
      'setup-step',
      currentSetupStep >= stepNum ? 'step-completed' : '',
      activeStep >= stepNum ? 'step-active' : '',
    ]"
  >
    <div class="step-container">
      <div class="step-contents">
        <div class="step-contents-body">
          <div class="empty-result">
            <img src="@/assets/img/send.svg" />
            <span class="empty-result-title">Almost there!</span>
            <span class="empty-result-description"
              >Now send an email to the seed list and after 5-15 minutes, click
              the button below to be taken to your Seed Test results page.</span
            >

            <a-button type="primary" class="btn-continue" @click="handleDone">
              Done. View My Seed Test Results
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
// import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Step-6',
  props: ['activeStep', 'stepNum', 'currentSetupStep'],
  setup(props, { emit }) {
    // const router = useRouter();

    const handleDone = () => {
      emit('continue');
      // router.push('/delivery/report');
      localStorage.setItem('current_setup_step', props.activeStep);
      window.location.href = '/delivery/report';
    };

    const handleChangeStep = () => {
      emit('updateActiveStep', props.stepNum);
    };

    return {
      handleChangeStep,
      handleDone,
    };
  },
});
</script>
