<template>
  <div
    :class="[
      'setup-step',
      currentSetupStep >= stepNum ? 'step-completed' : '',
      activeStep == stepNum ? 'step-active' : '',
    ]"
  >
    <div class="step-container">
      <div class="steps-container">
        <div class="steps-sub-label">
          Select regions and functionalities Select the Regions to use in
          testing and tracking your delivery reports. Your selections will
          update the seed list on the next screen.
        </div>
      </div>

      <div class="step-contents">
        <div class="step-contents-body">
          <div class="step-contents-body-items">
            <div class="form-group">
              <a-checkbox v-model:checked="regions.has_web_mail">
                Primary Web Email (AOL, Gmail, Yahoo, Hotmail)
              </a-checkbox>
            </div>
          </div>

          <div class="step-contents-body-items">
            <div class="step-contents-body-item">
              <p class="region">North America</p>

              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_us">US</a-checkbox>
              </div>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_canada"
                  >Canada</a-checkbox
                >
              </div>
              <div class="form-group">
                <a-checkbox
                  v-model:checked="regions.has_business_mail_providers"
                >
                  Business Mail Providers (Outlook, Google Workspace)
                </a-checkbox>
              </div>
            </div>
            <div class="step-contents-body-item">
              <p class="region">Europe</p>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_europe_uk"
                  >UK</a-checkbox
                >
              </div>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_europe_germany">
                  Germany
                </a-checkbox>
              </div>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_europe_france">
                  France
                </a-checkbox>
              </div>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_europe_italy">
                  Italy
                </a-checkbox>
              </div>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_europe_other">
                  Other
                </a-checkbox>
              </div>
            </div>
            <div class="step-contents-body-item">
              <p class="region">Asia / Pacific</p>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_asia_pacific_china">
                  China
                </a-checkbox>
              </div>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_asia_pacific_other">
                  Other
                </a-checkbox>
              </div>
            </div>
            <div class="step-contents-body-item">
              <p class="region">Central / South America</p>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_latam_mexico">
                  Mexico
                </a-checkbox>
              </div>
              <div class="form-group">
                <a-checkbox v-model:checked="regions.has_latam_other">
                  Other
                </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-footer">
      <a-button type="primary" @click="handleContinue"> Continue </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';

export default defineComponent({
  name: 'Step-4',
  props: ['activeStep', 'initialRegions', 'stepNum', 'currentSetupStep'],
  setup(props, { emit }) {
    const store = useStore();
    const regions = ref({});

    onMounted(() => {
      regions.value = props.initialRegions;
    });

    const handleChangeStep = () => {
      emit('updateActiveStep', props.stepNum);
    };

    const handleContinue = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.SetupApi.updateRegions(regions.value)
        .then(() => {
          emit('continue');
          localStorage.setItem('current_setup_step', props.activeStep);
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        })
        .catch(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    return {
      regions,
      handleChangeStep,
      handleContinue,
    };
  },
});
</script>

<style lang="less" scoped>
@import './Step4.less';
</style>
