<template>
  <a-modal
    width="80%"
    :open="open"
    centered
    :closable="false"
    :footer="null"
    @cancel="handleClose"
    wrap-class-name="header-modal reputation-modal"
  >
    <a-spin :spinning="loading">
      <a-layout>
        <a-layout-header>
          <div class="title">Add Domain</div>
          <div class="close-button">
            <a-config-provider :theme="getSpecialTheme()">
              <a-button @click="handleClose" type="text">
                <i class="fa-solid fa-xmark" />
              </a-button>
            </a-config-provider>
          </div>
        </a-layout-header>
        <a-layout-content class="main-content">
          <p>
            Enter the domain or IP address and add an identification label, then
            click "Add". To remove a domain or IP address from your
            <br />monitoring list, click the trash can to delete a row.
          </p>

          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <div class="form-group">
                <p class="form-label">Domain</p>
                <a-input class="w-full" v-model:value="rdnsDomain" />
              </div>
            </a-col>
            <a-col :span="6">
              <a-config-provider :theme="getSpecialTheme()">
                <div style="margin-top: 25px">
                  <a-button type="primary" @click="handleInsertRdnsDomain">
                    Add
                  </a-button>
                </div>
              </a-config-provider>
            </a-col>
          </a-row>

          <a-config-provider :theme="getSpecialTheme()">
            <a-table
              :columns="columns"
              :dataSource="dataSource"
              :pagination="false"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.name == 'action'">
                  <a-button type="link" @click="handleDeleteRdnsDomain(record)">
                    <img src="@/assets/img/delete.svg" />
                  </a-button>
                </template>
              </template>
            </a-table>
            <template #renderEmpty v-if="!loading">
              <div class="render-empty items-center flex flex-col">
                <img src="@/assets/img/icons/empty.svg" />
                <span>
                  <b>No domains added</b>
                  <br />
                  There's nothing to show at the moment.
                  <br />
                </span>
              </div>
            </template>
          </a-config-provider>
        </a-layout-content>
        <a-layout-footer>
          <a-config-provider :theme="getSpecialTheme()">
            <a-button type="primary" @click="handleClose">Finish</a-button>
          </a-config-provider>
        </a-layout-footer>
      </a-layout>
    </a-spin>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { defineComponent, ref, watch } from 'vue';

import api from '@/api';

const columns = [
  {
    title: 'Domain',
    dataIndex: 'domain',
  },
  {
    title: '',
    name: 'action',
    align: 'right',
  },
];

export default defineComponent({
  name: 'RdnsDomainSetupModal',
  props: ['open'],
  emits: ['close'],
  setup(props, { emit }) {
    const loading = ref(false);
    const dataSource = ref([]);
    const rdnsDomain = ref('');

    watch(
      () => props.open,
      (open) => {
        if (open) {
          rdnsDomain.value = '';
          getRdnsSummary();
        }
      }
    );

    const getRdnsSummary = () => {
      loading.value = true;

      api.RdnsApi.getRdnsSummary()
        .then((res) => {
          dataSource.value = res.data;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleInsertRdnsDomain = () => {
      loading.value = true;
      api.RdnsApi.addDomain(rdnsDomain.value)
        .then(() => {
          rdnsDomain.value = '';
          message.success('Domain added successfully');
          getRdnsSummary();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            message.error(err.response.data.detail);
          }
          loading.value = false;
        });
    };

    const handleDeleteRdnsDomain = (record) => {
      Modal.confirm({
        title: 'Remove Domain Confirmation',
        content: 'Are you sure want to remove this domain?',
        okText: 'Yes',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        cancelText: 'No',
        onOk() {
          loading.value = true;
          api.RdnsApi.deleteDomain(record.reverse_dns_id)
            .then(() => {
              message.success('Domain delete successfully');
              getRdnsSummary();
            })
            .catch(() => {
              loading.value = false;
            });
        },
      });
    };

    const handleClose = () => {
      if (loading.value) return;
      emit('close');
    };

    return {
      columns,
      loading,
      dataSource,
      rdnsDomain,
      handleInsertRdnsDomain,
      handleDeleteRdnsDomain,
      handleClose,
    };
  },
});
</script>

<style lang="less" scoped>
@import './RdnsDomainSetupModal.less';
</style>

<style lang="less">
.reputation-modal {
  .main-content {
    padding: 10px 50px 10px 50px;
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .ant-layout {
    height: calc(90vh);
  }

  .ant-table-wrapper {
    overflow-y: auto;
  }

  .ant-layout-header {
    border-bottom: none;
  }
}
</style>
