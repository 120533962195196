<template>
  <div class="modal-sidebar custom-steps-modal">
    <a-steps
      direction="vertical"
      v-model:current="activeStepForAStep"
      :items="setupItems"
      change="handleChangeActiveStep"
    ></a-steps>
  </div>
  <div v-if="showPage" class="content-section">
    <step-1
      :initial-domains="domains"
      :active-step="activeStep"
      :current-setup-step="currentSetupStep"
      @updateActiveStep="handleChangeActiveStep"
      @continue="handleContinueStep"
    />
    <step-2
      :active-step="activeStep"
      :initial-email-validation="emailValidation"
      :html-tracking="htmlTracking"
      :initial-emails="emails"
      :current-setup-step="currentSetupStep"
      :address-limit-exceeded="addressLimitExceeded"
      @updateActiveStep="handleChangeActiveStep"
      @continue="handleContinueStep"
    />
    <step-3
      v-if="showStep3"
      :active-step="activeStep"
      :current-setup-step="currentSetupStep"
      :initial-using-weighted-list="usingWeightedList"
      @updateActiveStep="handleChangeActiveStep"
      @continue="handleContinueStep"
    />
    <step-4
      v-if="showStep4"
      :active-step="activeStep"
      :initial-regions="regions"
      :step-num="showStep3 ? 4 : 3"
      :current-setup-step="currentSetupStep"
      @updateActiveStep="handleChangeActiveStep"
      @continue="handleContinueStepForStepFour"
    />
    <step-5
      :active-step="activeStep"
      :key-email-seeds="keyEmailSeeds"
      :key-email-seeds-only="keyEmailSeedsOnly"
      :current-setup-step="currentSetupStep"
      :step-num="showStep4 ? (showStep3 ? 5 : 4) : 3"
      @updateActiveStep="handleChangeActiveStep"
      @continue="handleContinueStep"
    />
    <step-6
      :active-step="activeStep"
      :current-setup-step="currentSetupStep"
      :step-num="showStep4 ? (showStep3 ? 6 : 5) : 4"
      @updateActiveStep="handleChangeActiveStep"
      @continue="handleContinueStep"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';

import Step1 from './components/Step1/Step1.vue';
import Step2 from './components/Step2/Step2.vue';
import Step3 from './components/Step3/Step3.vue';
import Step4 from './components/Step4/Step4.vue';
import Step5 from './components/Step5/Step5.vue';
import Step6 from './components/Step6/Step6.vue';

export default defineComponent({
  name: 'SetupAll',
  props: ['open'],
  emits: ['close'],
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
  },
  setup(props, { emit }) {
    const store = useStore();
    const serviceType = ref(0);
    const customerId = ref(0);
    const parentUserCustomerId = ref(0);
    const domains = ref([]);
    const activeStep = ref(0);
    const regions = ref({
      has_asia_pacific_china: false,
      has_asia_pacific_other: false,
      has_europe_uk: false,
      has_europe_germany: false,
      has_europe_france: false,
      has_europe_italy: false,
      has_europe_other: false,
      has_us: false,
      has_canada: false,
      has_business_mail_providers: false,
      has_web_mail: false,
      has_latam_mexico: false,
      has_latam_other: false,
    });
    const emailValidation = ref({});
    const htmlTracking = ref('');
    const emails = ref([]);
    const keyEmailSeeds = ref([]);
    const keyEmailSeedsOnly = ref([]);
    const currentSetupStep = ref(0);
    const usingWeightedList = ref(false);
    const showPage = ref(false);
    const addressLimitExceeded = ref(false);

    onMounted(() => {
      const user = JSON.parse(localStorage.getItem('user'));
      const parentUser = JSON.parse(localStorage.getItem('parent_user'));
      customerId.value = user.customer_id;
      parentUserCustomerId.value = parentUser.customer_id;
      serviceType.value = user.service_type;

      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      showPage.value = false;
      Promise.all([
        api.SetupApi.getAll(),
        api.ReputationApi.getAllDomainOrIp(),
        api.SetupApi.getFromEmail(),
        api.SetupApi.getCurrentSetupStep(),
        api.UserApi.addressLimitExceeded(),
      ])
        .then((res) => {
          regions.value = res[0].region;
          emailValidation.value = res[0].email_validation;
          htmlTracking.value = res[0].html_tracking;
          keyEmailSeeds.value = res[0].key_email_seeds;
          keyEmailSeedsOnly.value = res[0].key_email_seeds_only;
          usingWeightedList.value = res[0].using_weighted_list;
          domains.value = res[1].data;
          emails.value = res[2];
          currentSetupStep.value = res[3];
          addressLimitExceeded.value = res[4];
          activeStep.value = toNumber(currentSetupStep.value) + 1;
          console.log('Done');
        })
        .finally(() => {
          showPage.value = true;
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    });

    function toNumber(value) {
      return value !== null ? Number(value) : 0;
    }

    const handleChangeActiveStep = (newActiveStep) => {
      if (newActiveStep > toNumber(currentSetupStep.value) + 1) return;

      activeStep.value = newActiveStep;
    };

    const handleContinueStep = () => {
      if (activeStep.value > currentSetupStep.value) {
        currentSetupStep.value++;
      }

      const lastStep = showStep4.value ? (showStep3.value ? 5 : 4) : 3;
      if (currentSetupStep.value >= lastStep) {
        currentSetupStep.value++;
      }

      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.SetupApi.updateCurrentSetupStep(currentSetupStep.value)
        .then(() => {
          activeStep.value++;
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleContinueStepForStepFour = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.SetupApi.getAll()
        .then((res) => {
          keyEmailSeeds.value = res.key_email_seeds;
          keyEmailSeedsOnly.value = res.key_email_seeds_only;
          handleContinueStep();
        })
        .finally(() => {
          // store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const showStep3 = computed(
      () =>
        serviceType.value > 103 &&
        (customerId.value === 11394 || parentUserCustomerId.value === 11394)
    );

    const showStep4 = computed(() => serviceType.value > 101);

    const activeStepForAStep = computed({
      get() {
        return activeStep.value - 1; // activeStepForAStep is always activeStep - 1
      },
      set(value) {
        activeStep.value = value + 1; // When activeStepForAStep is changed, update activeStep
      },
    });

    const handleClose = () => {
      emit('close');
    };

    const setupItems = computed(() => {
      let items = [
        {
          title: 'Step 1',
          description: 'Reputation Setup',
        },
        {
          title: 'Step 2',
          description: 'Choose Validation Method',
        },
        {
          title: 'Step 3',
          description: 'Default Seed List',
        },
        {
          title: 'Step 4',
          description: 'Select Regions and Functionalities',
        },
        {
          title: 'Step 5',
          description: 'Copy Email Seeds',
        },
        {
          title: 'Step 6',
          description: 'Seed Test Results',
        },
      ];

      if (!showStep3.value & !showStep4.value) {
        items = [
          {
            title: 'Step 1',
            description: 'Reputation Setup',
          },
          {
            title: 'Step 2',
            description: 'Choose Validation Method',
          },
          {
            title: 'Step 3',
            description: 'Copy Email Seeds',
          },
          {
            title: 'Step 4',
            description: 'Seed Test Results',
          },
        ];
      } else if (!showStep3.value && showStep4.value) {
        items = [
          {
            title: 'Step 1',
            description: 'Reputation Setup',
          },
          {
            title: 'Step 2',
            description: 'Choose Validation Method',
          },
          {
            title: 'Step 3',
            description: 'Select Regions and Functionalities',
          },
          {
            title: 'Step 4',
            description: 'Copy Email Seeds',
          },
          {
            title: 'Step 5',
            description: 'Seed Test Results',
          },
        ];
      } else if (showStep3.value && !showStep4.value) {
        items = [
          {
            title: 'Step 1',
            description: 'Reputation Setup',
          },
          {
            title: 'Step 2',
            description: 'Choose Validation Method',
          },
          {
            title: 'Step 3',
            description: 'Default Seed List',
          },
          {
            title: 'Step 4',
            description: 'Copy Email Seeds',
          },
          {
            title: 'Step 5',
            description: 'Seed Test Results',
          },
        ];
      }

      return items;
    });

    return {
      serviceType,
      domains,
      activeStep,
      currentSetupStep,
      addressLimitExceeded,
      regions,
      emailValidation,
      htmlTracking,
      emails,
      keyEmailSeeds,
      keyEmailSeedsOnly,
      showStep3,
      showStep4,
      usingWeightedList,
      showPage,
      activeStepForAStep,
      setupItems,
      handleChangeActiveStep,
      handleContinueStep,
      handleContinueStepForStepFour,
      handleClose,
    };
  },
});
</script>

<style lang="less">
@import './All.less';
</style>
