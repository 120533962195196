<template>
  <a-modal
    width="90%"
    :open="open"
    centered
    :footer="null"
    @cancel="handleClose"
    wrap-class-name="custom-full-modal"
  >
    <setup-all />
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue';

import SetupAll from '@/views/Setup/All/All.vue';

export default defineComponent({
  name: 'SetupAllModal',
  components: {
    SetupAll,
  },
  props: ['open'],
  emits: ['close'],
  setup(props, { emit }) {
    const handleClose = () => {
      emit('close');
    };

    return {
      handleClose,
    };
  },
});
</script>
