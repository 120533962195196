<template>
  <a-spin :spinning="loadingPage" size="large" class="global-spinner">
    <Notification v-if="pageName == 'Overview'" />
    <a-layout>
      <a-layout-sider
        :collapsed="collapsed"
        :trigger="null"
        collapsible
        theme="light"
        width="270"
      >
        <div class="logo" v-if="!collapsed">
          <img src="@/assets/img/mm-new-black-logo.svg" />
        </div>

        <div class="logo-small" v-if="collapsed">
          <img src="@/assets/img/small-logo.svg" />
        </div>

        <div v-if="showGettingStarted" class="getting-started">
          <a-config-provider :theme="getSpecialTheme()">
            <a-button class="w-full" type="primary">
              <template #icon>
                <i class="fa fa-pencil mr-5" />
              </template>
              Getting Started
            </a-button>
          </a-config-provider>
        </div>

        <a-menu
          :selectedKeys="selectedKeys"
          mode="inline"
          :inlineIndent="12"
          :openKeys="openKeys"
          @click="onChangeMenu"
        >
          <a-menu-item key="overview">
            <a-icon :component="HomeIcon" :style="{ color: 'red' }" />
            <span>Overview</span>
          </a-menu-item>
          <a-sub-menu key="delivery">
            <template #icon>
              <a-icon :component="DeliveryIcon" />
            </template>
            <template #title>Delivery Monitors</template>
            <a-menu-item key="report">Seed Tests</a-menu-item>
            <a-menu-item key="isp-delivery-report">ISP Trends</a-menu-item>
            <a-menu-item key="gmail-tabs">Gmail Tabs</a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="reputation">
            <template #icon>
              <a-icon :component="ReputationIcon" />
            </template>
            <template #title>Reputation Monitors</template>
            <a-menu-item key="summary">Summary</a-menu-item>
            <a-menu-item key="rdns-summary">RDNS</a-menu-item>
            <a-menu-item
              v-if="[102, 103, 104].includes(user.service_type)"
              key="microsoft-snds"
            >
              Microsoft SNDS
            </a-menu-item>
            <a-menu-item key="postmaster-reports">
              Google Postmaster
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="dmarc">
            <template #icon>
              <a-icon :component="DmarcIcon" />
            </template>
            <template #title>DMARC</template>
            <a-menu-item key="dmarc-summary">Summary</a-menu-item>
            <a-menu-item key="checker">Checker</a-menu-item>
          </a-sub-menu>
          <a-sub-menu
            v-if="
              user.show_opentracker == 1 ||
              [100, 101].includes(user.service_type)
            "
            key="opentracker"
          >
            <template #icon>
              <a-icon :component="DeliveryIcon" />
            </template>
            <template #title>Engagement Monitors</template>
            <a-menu-item key="index">Opentracker</a-menu-item>
          </a-sub-menu>
          <a-menu-item
            v-if="disablePreview === null"
            key="email-preview/preview-summary"
          >
            <a-icon :component="DesignIcon" />
            <span>Design Monitors</span>
          </a-menu-item>
          <a-menu-item
            v-if="![100, 101].includes(user.service_type)"
            key="alerts/index"
          >
            <a-icon :component="AlertIcon" />
            <span>Alerts</span>
          </a-menu-item>
        </a-menu>
        <div class="footer" v-if="!collapsed">
          Copyright MailMonitor &copy; 2008-{{ currentYear }}
        </div>
      </a-layout-sider>
      <div
        class="collapse-side-bar"
        :style="{ left: collapsed ? '68px' : '258px' }"
      >
        <right-outlined
          v-if="collapsed"
          class="trigger"
          @click="handleCollapsed"
        />
        <left-outlined v-else class="trigger" @click="handleCollapsed" />
      </div>
      <a-layout>
        <Header @handleCollapsed="collapsed = !collapsed" />
        <a-layout-content
          :style="{
            background: '#fff',
            overflowY: 'auto',
          }"
        >
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-spin>
</template>

<script>
import Icon, { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';

import Header from '../Header/Header.vue';
import Notification from '../Notification/Notification.vue';
import AlertIcon from './components/AlertIcon.vue';
import DeliveryIcon from './components/DeliveryIcon.vue';
import DesignIcon from './components/DesignIcon.vue';
import DmarcIcon from './components/DmarcIcon.vue';
import EngagementIcon from './components/EngagementIcon.vue';
import HomeIcon from './components/HomeIcon.vue';
import ReputationIcon from './components/ReputationIcon.vue';

export default defineComponent({
  components: {
    AIcon: Icon,
    LeftOutlined,
    RightOutlined,
    Header,
    Notification,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const selectedKeys = ref([]);
    const openKeys = ref([]);
    const collapsed = ref(false);
    const currentYear = ref(dayjs().year());
    const user = ref({});
    const currentSetupStep = ref(0);
    const disablePreview = ref(null);

    onMounted(() => {
      user.value = JSON.parse(localStorage.getItem('user'));
      currentSetupStep.value = localStorage.getItem('current_setup_step');
      disablePreview.value = user.value.disable_preview;

      if (user.value.json_body && user.value.json_body.disablePreview) {
        disablePreview.value = user.value.json_body.disablePreview;
      } else {
        disablePreview.value = null;
      }

      updateMenuKeys();

      const zendeskScript = document.createElement('script');
      zendeskScript.setAttribute(
        'src',
        'https://static.zdassets.com/ekr/snippet.js?key=26cfe0fe-c88d-41e1-8a28-ec8212c22a7e'
      );
      zendeskScript.setAttribute('id', 'ze-snippet');
      document.head.appendChild(zendeskScript);
    });

    const updateMenuKeys = () => {
      const list = router.currentRoute.value.path.split('/');
      list.shift();
      selectedKeys.value = [...list];
      list.pop();
      openKeys.value = [...list];
    };

    const onChangeMenu = (event) => {
      selectedKeys.value = [event.key];
      router.push({
        path: `/${event.keyPath.join('/')}`,
      });
    };

    const showGettingStarted = computed(
      () =>
        [102, 103, 104, 105].includes(user.value.service_type) &&
        currentSetupStep.value < 4
    );

    const handleCollapsed = () => {
      collapsed.value = !collapsed.value;
    };

    watch(() => route.path, updateMenuKeys, {
      immediate: true,
    });

    return {
      user,
      collapsed,
      selectedKeys,
      openKeys,
      currentYear,
      showGettingStarted,
      disablePreview,
      loadingPage: computed(() => store.state.loadingPage),
      pageName: computed(() => router.currentRoute.value.name),
      onChangeMenu,
      handleCollapsed,
      AlertIcon,
      DeliveryIcon,
      DesignIcon,
      DmarcIcon,
      EngagementIcon,
      HomeIcon,
      ReputationIcon,
    };
  },
});
</script>

<style lang="less">
@import './PageLayout.less';
</style>
